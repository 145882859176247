/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Fix to prevent blocking the AccountsDropdown. All options in dropdown should be clickable. */
.z-index-top {
  z-index: 99999 !important;
}

.z-index-one {
  z-index: 1 !important;
}

.move-to-second-to-bottom {
  z-index: -1 !important;
}

.move-to-bottom {
  z-index: -2 !important;
}

.rainbow-text {
  /* start of rainbow */
  background: #fff; /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(
    left,
    #888585,
    #f0efef,
    #3aa1e7,
    #8571f6,
    #9b614d,
    #b72b35,
    #e435a1,
    #bac139,
    #20c0c2,
    #48c442
  ); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(
    right,
    #888585,
    #f0efef,
    #3aa1e7,
    #8571f6,
    #9b614d,
    #b72b35,
    #e435a1,
    #bac139,
    #20c0c2,
    #48c442
  ); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(
    right,
    #888585,
    #f0efef,
    #3aa1e7,
    #8571f6,
    #9b614d,
    #b72b35,
    #e435a1,
    #bac139,
    #20c0c2,
    #48c442
  ); /* For Firefox 3.6 to 15 */
  background: linear-gradient(
    to right,
    #888585,
    #f0efef,
    #3aa1e7,
    #8571f6,
    #9b614d,
    #b72b35,
    #e435a1,
    #bac139,
    #20c0c2,
    #48c442
  ); /* Standard syntax (must be last) */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* end of rainbow */
}


/* html,
body {
  background-color: #000;
} */